import React, { useRef, useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PlayerDyn from './PlayerDyn';
import Playlist from './Playlist';
import MapPopup from './MapPopup';
import IndoorMap from './IndoorMap';
import QrReader from './QrReader';
import Password from './Password';
import NavigationTabs from './NavigationTabs';
import { fetchData } from './utility/ApiRequests';
import { WalkDataContext } from './hooks/walkDataContext';
import { Button, Container } from 'react-bootstrap';
import StoryDetails from './StoryDetails';
import {getStoryMetadataValue} from './MetadataFetcher';

const AudioPlayer = () => {
  const { trackSlug, walkSlug } = useParams();
  const {
    storyData, setStoryData,
    language, setLanguage,
    storyMetas, setStoryMetas,
    tracks, setTracks,
    currentTrack, setCurrentTrack,
    playerSize, setPlayerSize,
    activeTab, setActiveTab,
    isPlaying, setIsPlaying,
    handleTabChange,
    password, setPassword,
    isPasswordValid, setIsPasswordValid,
    validatePassword,
    validTrackSlugs,
    passwordAttempted, setPasswordAttempted
  } = useContext(WalkDataContext);

  const [walkData, setWalkData] = useState(null);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef();
  const progressBarRef = useRef();

  useEffect(() => {
    const initFetch = async () => {
      try {
        const data = await fetchData('/api/getStory/' + walkSlug);
        setWalkData(data);
        setStoryMetas(data.story.metadata);
        setTracks(data.tracks);
        setStoryData(data);
  
        if (data.tracks && data.tracks.length > 0) {
          // Find the initial track without password protection
          let initialTrack = data.tracks.find(track => !track.metadata?.trackMeta_password);
  
          if (trackSlug) {
            const foundTrack = data.tracks.find(track => track.slug.toString() === trackSlug.toString());
            if (foundTrack) {
              initialTrack = foundTrack;
              setActiveTab('home');
            }
          }
  
          setCurrentTrack(initialTrack);
        }
      } catch (error) {
        console.error('Fehler beim Laden des Walks: ', error);
      }
    };
  
    if (walkSlug) {
      initFetch();
    }
  }, []);

  const handleTrack = useCallback((slug) => {
    const track = tracks.find(track => track.slug === slug);
    if (track) {
      setCurrentTrack(track);
      if (track.metadata?.trackMeta_audiotrack?.[language]) {
        audioRef.current.src = track.metadata.trackMeta_audiotrack[language];
        audioRef.current.load();
        audioRef.current.play().then(() => setIsPlaying(true)).catch(e => {
          console.error('Error playing the track:', e);
          setIsPlaying(false);
        });
      } else {
        console.error('Audio source is undefined');
      }
    } else {
      setActiveTab('info');
      console.error('Track not found for slug:', slug);
    }
  }, [tracks, language, setCurrentTrack, setIsPlaying]);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    audioRef.current.play().catch(e => console.error("Error playing the track:", e));
  };

  const updateMediaSession = (currentTrack, metas, language) => {
    const getThumbnail = () => {
      return currentTrack.metadata.trackMeta_cover && currentTrack.metadata.trackMeta_cover[language]
        ? currentTrack.metadata.trackMeta_cover[language]
        : metas.storyMeta_cover && metas.storyMeta_cover[language]
          ? metas.storyMeta_cover[language]
          : '/assets/illustrations/placeholder-cover.jpg';
    };

    const getArtist = () => {
      return currentTrack.metadata.trackMeta_author && currentTrack.metadata.trackMeta_author[1]
        ? currentTrack.metadata.trackMeta_author[1]
        : metas.storyMeta_author && metas.storyMeta_author[1]
          ? metas.storyMeta_author[1]
          : '';
    };

    if ('mediaSession' in navigator) {
      const thumbnail = getThumbnail();
      const artist = getArtist();

      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: currentTrack.metadata.trackMeta_name[language],
        artist: artist,
        album: metas.storyMeta_title[language],
        artwork: [
          { src: thumbnail, sizes: '512x512', type: 'image/png' }
        ]
      });

      navigator.mediaSession.setActionHandler('play', () => {
        setIsPlaying(true);
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        setIsPlaying(false);
      });
    }
  };

  useEffect(() => {
    if (storyMetas !== null) {
      const title = getStoryMetadataValue(storyMetas, language, 'storyMeta_title', 'Ortsieler');
      const favicon = getStoryMetadataValue(storyMetas, language, 'storyMeta_cover', '/assets/illustrations/placeholder-cover.jpg');

      document.title = title;
      const link = document.querySelector("link[rel~='icon']");
      if (link) {
        link.href = favicon;
      } else {
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = favicon;
        document.head.appendChild(newLink);
      }

      if (currentTrack !== null) {
        updateMediaSession(currentTrack, storyMetas, language);
      }
    }
  }, [storyMetas, language]);

  useEffect(() => {
    if (currentTrack !== null && storyMetas !== null) {
      updateMediaSession(currentTrack, storyMetas, language);
    }
  }, [currentTrack, storyMetas, language]);

  const resetPlayer = useCallback(() => {
    setIsPlaying(false);
    setTimeProgress(0);
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  }, [setTimeProgress, setIsPlaying]);

  const memoizedPlayerDyn = useMemo(() => (
    <PlayerDyn
      size={playerSize}
      currentTrack={currentTrack}
      metas={storyMetas}
      language={language}
      audioRef={audioRef}
      progressBarRef={progressBarRef}
      duration={duration}
      setTimeProgress={setTimeProgress}
      isPlaying={isPlaying}
      togglePlayPause={togglePlayPause}
      timeProgress={timeProgress}
      resetPlayer={resetPlayer}
      handleTrack={handleTrack}
      setActiveTab={setActiveTab}
    />
  ), [playerSize, currentTrack, storyMetas, language, duration, isPlaying, timeProgress, resetPlayer, handleTrack]);

  const filteredTracks = useMemo(() => {
    if (!storyMetas?.storyMeta_trackpasswords) {
      return tracks;
    } else {
      return tracks?.filter(track => 
        !track.metadata?.trackMeta_password || validTrackSlugs.includes(track.slug)
      );
    }
  }, [tracks, validTrackSlugs, storyMetas]);

  return (
    <>
      <Container className='ml-125'>
        {!storyMetas || !tracks ? (
          <div>Loading...</div>
        ) : (
          <>
            <audio ref={audioRef} onLoadedMetadata={onLoadedMetadata} onEnded={resetPlayer}>
              {currentTrack?.metadata?.trackMeta_audiotrack?.[language] && (
                <source src={currentTrack.metadata.trackMeta_audiotrack[language]} type="audio/mpeg" />
              )}
              Your browser does not support the audio element.
            </audio>

            {storyMetas && currentTrack && memoizedPlayerDyn}

            {filteredTracks && (
              <>
                {activeTab === 'info' && (
                  <>
                    <StoryDetails metas={storyMetas} language={language} />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}

                {activeTab === 'playlist' && (
                  <>
                    <Playlist
                      tracks={filteredTracks}
                      handleTrack={handleTrack}
                      currentTrackId={currentTrack ? currentTrack.slug : 0}
                      language={language}
                      metas={storyMetas}
                    />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}

                {activeTab === 'password' && (
                  <>
                  <Password
                    password={password}
                    setPassword={setPassword}
                    validatePassword={validatePassword}
                    isPasswordValid={isPasswordValid}
                    passwordAttempted={passwordAttempted}
                  />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}

                {activeTab === 'map' && (
                  <>
                    <MapPopup
                      tracks={filteredTracks}
                      handleTrack={handleTrack}
                      currentTrackSlug={currentTrack ? currentTrack.slug : 0}
                      paths={null}
                      language={language}
                      metas={storyMetas}
                    />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}

                {activeTab === 'qr' && (
                  <>
                    <QrReader
                      tracks={tracks}
                      walkSlug={walkSlug}
                      handleTrack={handleTrack}
                      setActiveTab={setActiveTab}
                    />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}
              </>
            )}

            <NavigationTabs activeTab={activeTab} onTabChange={handleTabChange} />
          </>
        )}
      </Container>
    </>
  );
};

export default AudioPlayer;