import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { WalkDataProvider, WalkDataContext } from './components/hooks/walkDataContext';
import UserManager from './components/auth/UserManager';
import { NotificationProvider } from './components/hooks/useNotifications';
import { AuthProvider } from './components/auth/AuthContext';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import HomePage from './pages/HomePage';
import Code from './pages/Code';
import AdminPage from './pages/AdminPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

import GetWalk from './components/GetWalk';
import NavigationTabs from './components/NavigationTabs';

if (process.env.NODE_ENV === 'development') {
    axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'true';
}

const AppContent = () => {
  const location = useLocation();
  const { setStoryData } = useContext(WalkDataContext);

  useEffect(() => {
    if (location.pathname.startsWith('/b')) {
      setStoryData(null);
    } else {
      setStoryData('');
    }
  }, [location, setStoryData]);

  return (
    <div>
      <div id="main-container">
        <div id="header">
          <Header />
        </div>
        <div id="content">
          <Routes>
            <Route path="/a/:walkSlug/:trackSlug" element={<GetWalk />} />
            <Route path="/a/:walkSlug" element={<GetWalk />} />
            <Route path="/s" element={<GetWalk />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/code/:activationCode" element={<Code />} />
            <Route path="/code/" element={<Code />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/admin/*" element={<AdminPage />} />
            {/* Weitere Routen hier */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <UserManager />
        <NotificationProvider>
          <WalkDataProvider>
            <AppContent />
          </WalkDataProvider>
        </NotificationProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;