import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faMusic, faMapMarkedAlt, faQrcode, faKey } from '@fortawesome/free-solid-svg-icons';
import { WalkDataContext } from './hooks/walkDataContext';

function NavigationTabs({ activeTab, onTabChange }) {

  const { storyMetas } = useContext(WalkDataContext);

  // Funktion zur Bestimmung des Icons basierend auf dem Tab-Namen
  const getIconForTab = (tab) => {
    const icons = {
      home: faHome,
      playlist: faMusic,
      map: faMapMarkedAlt,
      qr: faQrcode,
      password: faKey
    };
    return icons[tab];
  };

  // Initialisierung der Tabs
  let navigationTabs = ['home', 'playlist', 'map', 'qr'];

  // Bedingung für das Hinzufügen des 'password'-Tabs an der zweiten Position
  if (storyMetas && storyMetas.storyMeta_trackpasswords && parseFloat(storyMetas.storyMeta_trackpasswords[1]) > 0) {
    navigationTabs.splice(1, 0, 'password');
  }

  // Funktion zur Großschreibung des ersten Buchstabens für die visuelle Darstellung
  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

  return (
    <nav className="navbar fixed-bottom navbar-light bg-light">
      <div className="container-fluid justify-content-around">
        {navigationTabs.map(tab => (
          <div key={tab} className="nav-item">
            <button
              className={`btn btn-link ${activeTab === tab ? 'active' : ''}`}
              onClick={() => onTabChange(tab)}
            >
              <FontAwesomeIcon icon={getIconForTab(tab)} />
              <span className="visually-hidden">{capitalize(tab)}</span>
            </button>
          </div>
        ))}
      </div>
    </nav>
  );
}

export default NavigationTabs;
