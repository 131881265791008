import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const fetchData = async (route, token = null, params = null) => {
    try {
        const response = await axios.get(`${API_BASE_URL + route}`, {
            headers: { 'authorization': `Bearer ${token}` },
            params: params
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};