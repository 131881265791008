import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import {getStoryMetadataValue} from './MetadataFetcher';

const StoryDetails = ({metas, language}) => {


const storyTitle = getStoryMetadataValue(metas, language, 'storyMeta_title', '-- ohne Titel --');
const storySubTitle = getStoryMetadataValue(metas, language, 'storyMeta_subtitle', '');
const storyAuthor = getStoryMetadataValue(metas, 1, 'storyMeta_author', '');
const storyCover = getStoryMetadataValue(metas, language, 'storyMeta_cover', '/assets/illustrations/placeholder-cover.jpg');
const storyDescription = getStoryMetadataValue(metas, language, 'storyMeta_description', '', true);
const storyDuration = getStoryMetadataValue(metas, 1, 'storyMeta_duration', '');
const storyReleasedate = getStoryMetadataValue(metas, 1, 'storyMeta_releasedate', '');

function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const monthNames = [
    "Januar", "Februar", "März", "April", "Mai", "Juni",
    "Juli", "August", "September", "Oktober", "November", "Dezember"
  ];

  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${year}`;
}

const formatMinutes = (minutes) => {
  if (minutes < 60) {
    return `etwa ${Math.round(minutes)} Minuten`;
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let roundedMinutes;
    if (remainingMinutes === 0) {
      return `etwa ${hours} Stunde${hours > 1 ? 'n' : ''}`;
    } else if (remainingMinutes <= 15) {
      roundedMinutes = 15;
    } else if (remainingMinutes <= 30) {
      roundedMinutes = 30;
    } else if (remainingMinutes <= 45) {
      roundedMinutes = 45;
    } else if (remainingMinutes <= 60) {
      roundedMinutes = 0;
    }

    if (roundedMinutes === 0) {
      return `etwa ${hours + 1} Stunde${hours + 1 > 1 ? 'n' : ''}`;
    } else if (roundedMinutes === 30) {
      return `etwa ${hours}.5h`;
    } else {
      return `etwa ${hours}h ${roundedMinutes} min`;
    }
  }
};


  return (
    <div className="card-container">
      <Card>
        <Card.Img variant="top" src={storyCover} alt={`${storyTitle} Coverbild`} className='card-coverimage' /><br />
        <Card.Body>
          {storyAuthor && 
            <span className='track-description'> {storyAuthor} </span>
          }
          <Card.Title>{storyTitle}</Card.Title>
          {storySubTitle && 
            <span className='track-description pb-3'> {storySubTitle} </span>
          }
          {storyDescription && <Card.Text>
            <span className='track-description' dangerouslySetInnerHTML={storyDescription} />
          </Card.Text>}
        </Card.Body>
        <ListGroup className="list-group-flush">
        {(storyDuration || storyReleasedate) && (
            <ListGroup.Item>
              {storyDuration && <span>{formatMinutes(storyDuration)}</span>}
              {storyReleasedate && <span className='ml-150'>{formatDate(storyReleasedate)}</span>}
            </ListGroup.Item>
          )}
        </ListGroup>

      </Card>
    </div>
  );
};

export default StoryDetails;
