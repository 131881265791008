import React, { useState, useMemo } from 'react';

export default function useSortableData(items, config = { key: null, direction: 'asc', type: 'alpha' }) {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] == null && b[sortConfig.key] == null) return 0;
                if (a[sortConfig.key] == null) return 1;
                if (b[sortConfig.key] == null) return -1;

                const isNumericSort = sortConfig.type === 'numeric';
                const isDateSort = sortConfig.type === 'date';

                let order = sortConfig.direction === 'asc' ? 1 : -1;

                if (isDateSort) {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    return order * (dateA - dateB);
                } else if (isNumericSort) {
                    const numA = parseFloat(a[sortConfig.key]);
                    const numB = parseFloat(b[sortConfig.key]);
                    return order * (numA - numB);
                } else {
                    return order * (a[sortConfig.key]?.localeCompare(b[sortConfig.key]));
                }
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key, type = 'alpha') => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction, type });
    };

    return { items: sortedItems, requestSort, sortConfig };
};
