import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { fetchData } from '../../utility/ApiRequests';
import { useNotification } from '../../hooks/useNotifications';


const BackupDb = () => {
    const route = '/api/backupDb';
    const contextSingular = 'Backup DB';
    const contextPlural = 'Backup DB';
    const contentSlugPrefix = 'category_';
    const dataBseName='categories';

    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');



        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);

                console.log(data);


            } catch (error) {
                console.error('Fehler beim Laden der ' + contextPlural + ':', error);
                showNotification('Fehler beim Laden der ' + contextPlural + '.', 'danger');
            }
        };


    return (
        <div>
            <h2>{contextPlural}</h2>
            <div className="content-right">
                <Button onClick={() => initFetch()}>
                    Backup
                </Button>
            </div>
    
        </div>
    );
};

export default BackupDb;
