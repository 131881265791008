import React, { useState, useRef } from 'react';
import { Card, Button} from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import EditableField from './EditableField';
import { updateTrackPositionsInDB } from './dataHandling';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { v4 as uuidv4 } from 'uuid';

const DraggableCard = ({ track, index, trackOrder, setTrackOrder, moveTrack, selectedLanguage, deleteTrack, thiskey }) => {
    const ref = useRef(null);
    const originalIndex = useRef(index);
    const [canDrag, setCanDrag] = useState(false);
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const navigate = useNavigate();

    const [{ isDragging }, drag, preview] = useDrag({
        type: 'CARD',
        item: { index },
        canDrag: () => canDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: async (item, monitor) => {
            setCanDrag(false);
            const clientOffset = monitor.getClientOffset();
            const boundingRect = ref.current.getBoundingClientRect();

            if (!monitor.didDrop()) {
                if (clientOffset.y < boundingRect.top) {
                    moveTrack(item.index, 0, true);
                } else if (clientOffset.y > boundingRect.bottom) {
                    moveTrack(item.index, trackOrder.length - 1, true);
                } else {
                    moveTrack(item.index, originalIndex.current);
                }
            } else {
                if (item.index !== originalIndex.current) {
                    await updateTrackPositionsInDB(trackOrder);
                }
            }
        },
    });

    const [, drop] = useDrop({
        accept: 'CARD',
        hover(item) {
            if (item.index !== index) {
                moveTrack(item.index, index);
                item.index = index;
            }
        },
    });

    const handleMouseDown = () => {
        originalIndex.current = index;
        setCanDrag(true);
    };

    const handleMouseUp = () => {
        setCanDrag(false);
    };

    const moveUp = () => {
        if (index > 0) {
            moveTrack(index, index - 1, true);
        }
    };

    const moveDown = () => {
        if (index < trackOrder.length - 1) {
            moveTrack(index, index + 1, true);
        }
    };

    drag(drop(ref));
    preview(ref);

    const uniqueKey = uuidv4();


    return (
        <div key={thiskey}
            ref={ref}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            onMouseUp={handleMouseUp}
        >
            <Card className="draggable-card mb-3 d-flex align-items-center">
                <div
                    className="drag-handle pr-1"
                    style={{
                        cursor: 'move',
                        width: isTouchDevice ? '5rem' : '3rem',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#e0e0e0',
                        borderTopLeftRadius: '4px',
                        borderBottomLeftRadius: '4px',
                        marginRight: '0.5rem',
                        position: 'relative',
                    }}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                >
                    {isTouchDevice ? (
                        <div className="flex pl-1" style={{ flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                            {index > 0 ? (
                                <button onClick={moveUp} className="bor-25" style={{ border: 'none', color: 'white', backgroundColor: 'var(--bs-primary)', width: '2rem' }}>
                                    <FontAwesomeIcon icon={faArrowUp} />
                                </button>
                            ) : (
                                <div style={{ width: '2rem' }} />
                            )}
                            {index < trackOrder.length - 1 ? (
                                <button onClick={moveDown} className="bor-25" style={{ border: 'none', color: 'white', backgroundColor: 'var(--bs-primary)', width: '2rem', marginLeft: '0.5rem' }}>
                                    <FontAwesomeIcon icon={faArrowDown} />
                                </button>
                            ) : (
                                <div style={{ width: '2rem', marginLeft: '0.5rem' }} />
                            )}
                        </div>
                    ) : (
                        track.position
                    )}
                </div>
                <div className="flex-grow-1 d-flex align-items-center">
                    <div className="flex-shrink-0" style={{ width: '10rem', textAlign: 'left' }}>
                        <Card.Title className="mb-0" style={{ fontSize: '1rem' }}>
                            <EditableField
                                value={track[`${selectedLanguage}_name`] || ''}
                                minValue="0"
                                maxValue="100"
                                size="15"
                                placeholder="Titel des Tracks"
                                ifEmpty="Unbenannter Track"
                                fieldType="text"
                            />
                        </Card.Title>
                    </div>
                    <div style={{ width: '15rem' }}>
                        {track[`${selectedLanguage}_audioUrl`] && (
                            <audio controls style={{ width: '100%' }} key={uniqueKey}>
                                <source src={track[`${selectedLanguage}_audioUrl`]} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        )}
                    </div>
                    <div style={{ width: '2rem', textAlign: 'center' }}>
                        <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            style={{ width: '24px', height: '24px' }}
                            color={track.geoLocation ? 'blue' : 'gray'}
                        />
                    </div>
                </div>
                <Button className='mr-1' onClick={() => navigate(`../tracks/${track.id}`)}>bearbeiten</Button>
                <Button className='mr-1'>
                <Trash
                    onClick={() => deleteTrack(track.track_id ,track.id)}
                />
                </Button>
            </Card>
        </div>
    );
};

export default DraggableCard;
