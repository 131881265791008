import React, { useState, useEffect } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { fetchData } from '../../utility/ApiRequests';
import { handleSaveChanges as handleSaveChangesUtil, handleNewEntry, handleDeleteEntry, newEntry } from '../../utility/dataHandling';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import EditableField from '../../utility/EditableField';
import SortIndicator from '../../utility/SortIndicator';
import {storyStateOptiones } from '../../utility/DropdownOptions';
import { createUniqueSlug } from '../../utility/Slug';

const StoriesTab = () => {
    const route = '/api/stories';
    const contextSingular = 'Story';
    const contextPlural = 'Stories';
    const dataBseName='stories';

    const [contextData, setContextData] = useState([]);
    const [formattedContents, setFormattedContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [newEntryName, setNewEntryName] = useState('');
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(formattedContents);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    const handleSaveChanges = handleSaveChangesUtil(setFormattedContents, showNotification);
 
    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der ' + contextPlural + ':', error);
                showNotification('Fehler beim Laden der ' + contextPlural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, []);

    function transformData(data) {
        const { stories, storyContents } = data;
    
        // Erstellen einer Map für schnellen Zugriff auf storyContents basierend auf story_id
        const contentMap = new Map();
        storyContents.forEach(content => {
            contentMap.set(content.story_id, content.title);
        });
        // Erstellen des neuen Arrays mit verknüpften Daten
        const mergedData = stories.map(story => {
            return {
                id: story.id,
                state: story.state,
                slug: story.slug,
                title: contentMap.get(story.id) || null,
                //content: contentMap.get(story.id) || null // Falls kein Inhalt vorhanden ist, setzen wir den Inhalt auf null
            };
        });
        return mergedData;
    }

    useEffect(() => {
        if (contextData && contextData.stories && contextData.storyContents) {
            setFormattedContents(transformData(contextData));
        }
    }, [contextData]);

    const handleAddNewEntry = () => {
        setIsAdding(true);
    };

    const handleCancelAdd = () => {
        setIsAdding(false);
        setNewEntryName('');
    };


    const handleSaveNewEntry = async () => {
        try {

            const existingSlugs = sortedContextData.map(data => data.slug);
            const slug = createUniqueSlug(newEntryName, existingSlugs);

            const newStory = await newEntry(dataBseName, showNotification, {
                'state':'private',
                'slug':slug
            });
            const newStoryId = newStory['id'];
            const newStoryState = newStory['state'];

            const contentId = await newEntry('text_contents', showNotification, {
                'content': newEntryName,
            });
            const newContentId = contentId['id'];
    
            await newEntry('story_contents', showNotification, {
                'story_id': newStoryId,
                'meta_category_id': '1',  // Stimmt nur wenn id 1 gleich title ist
                'content_id': newContentId,
                'language_id': '2'  // Stimmt nur wenn id 2 gleich deutsch ist
            });
    
            // FormattedContents mit dem neuen Eintrag aktualisieren
            setFormattedContents(prevContents => [
                ...prevContents,
                {
                    id: newStoryId,
                    state: newStoryState,
                    slug:slug,
                    title: newEntryName
                }
            ]);
    
            setIsAdding(false);
            setNewEntryName('');
        } catch (error) {
            console.error('Fehler beim Speichern des neuen Eintrags:', error);
            showNotification('Fehler beim Speichern des neuen Eintrags.', 'danger');
        }
    };

    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }

    return (
        <div>
            <Button onClick={() => console.log(contextData)}>
                DEBUG1
            </Button>
            <Button onClick={() => console.log(sortedContextData)}>
                DEBUG2
            </Button>
            <h2>{contextPlural}</h2>
            <div className='content-right'><Button className='' onClick={() => setEditActive(!editActive)}> Bearbeitung {editActive ? 'deaktivieren' : 'aktivieren'}</Button></div>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('id', 'numeric')}>
                            ID
                            <SortIndicator isSorted={sortConfig.key === 'id'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('title')}>
                            Name
                            <SortIndicator isSorted={sortConfig.key === 'title'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('state')}>
                            State
                            <SortIndicator isSorted={sortConfig.key === 'state'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('slug')}>
                            Slug
                            <SortIndicator isSorted={sortConfig.key === 'slug'} direction={sortConfig.direction} />
                        </th>

                        <th>
                            Details
                        </th>

                        {editActive &&
                            <th>
                                löschen
                            </th>
                        }
                        {/* Weitere Spalten hier */}
                    </tr>
                </thead>
                <tbody>
                    {sortedContextData.map(lan => {

                        return (
                            <tr key={lan.id}>
                                <td>{lan.id}</td>
                                <td>{lan.title}</td>
                                <td>{
                                    editActive ?
                                        <EditableField
                                            value={lan.state}
                                            onSave={(newValue) => handleSaveChanges(newValue, 'state', lan.id, dataBseName)}
                                            fieldType="dropdown"
                                            dropdownOptions={storyStateOptiones()}
                                       />
                                        :
                                        lan.state
                                }
                                </td>
                                <td>{
                                    editActive ?
                                        <EditableField
                                            value={lan.slug}
                                            onSave={(newValue) => handleSaveChanges(newValue, 'slug', lan.id, dataBseName)}
                                            fieldType="text"
                                       />
                                        :
                                        lan.slug
                                }
                                </td>
                                <td>
                                
                                    <Link to={`./${lan.id}`}>bearbeiten</Link>
                                </td>

                                {editActive &&
                                    <td>
                                        <Trash
                                            onClick={() => handleDeleteEntry(dataBseName, lan.id, setFormattedContents, showNotification)}
                                        ></Trash>
                                    </td>
                                }
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {!isAdding && (
                <Button onClick={handleAddNewEntry}>Neue {contextSingular} hinzufügen</Button>
            )}
            {isAdding && (
                <div>
                    <input
                        type="text"
                        value={newEntryName}
                        onChange={(e) => setNewEntryName(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSaveNewEntry()}
                        placeholder={`${contextSingular}name auf Deutsch`}
                    />
                    <Button onClick={handleSaveNewEntry}>Speichern</Button>
                    <Button onClick={handleCancelAdd}>Abbrechen</Button>
                </div>
            )}
        </div>
    );
};

export default StoriesTab;
