import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import {getTrackMetadataValue} from './MetadataFetcher';

const TrackDetails = ({currentTrack, metas, language}) => {


const trackTitle = getTrackMetadataValue(currentTrack, metas, language, 'trackMeta_name', 'storyMeta_name', '-- ohne Titel --');
const trackAuthor = getTrackMetadataValue(currentTrack, metas, 1, 'trackMeta_author', 'storyMeta_author', '');
const trackCover = getTrackMetadataValue(currentTrack, metas, language, 'trackMeta_cover', 'storyMeta_cover', '/assets/illustrations/placeholder-cover.jpg');
const trackDescription = getTrackMetadataValue(currentTrack, metas, language, 'trackMeta_description', 'storyMeta_description', '', true);

  return (
    <div className="card-container">
      <Card>
        <Card.Img variant="top" src={trackCover} alt={`${trackTitle} Coverbild`} className='card-coverimage'  /><br />
        <Card.Body>
          {trackAuthor && <Card.Text>
            <span className='track-description'> {trackAuthor} </span>
          </Card.Text>}
          <Card.Title>{trackTitle}</Card.Title>
          {trackDescription && <Card.Text>
            <span className='track-description' dangerouslySetInnerHTML={trackDescription} />
          </Card.Text>}
        </Card.Body>
      </Card>
      <div className='miniplayer-spacer'></div>
      <div className='navmenu-spacer'></div>
      
    </div>
    
  );
};

export default TrackDetails;
