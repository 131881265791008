import React, { useState } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import PromptFunctions from './promptEditor/functions/FunctionsTab';
import FunctionDetail from  './promptEditor/functions/FunctionDetail';
import InputTypesManager from './promptEditor/inputs/InputTypeTab';
import InputTypesDetail from  './promptEditor/inputs/InputTypeDetail';
import InputModulesManager from './promptEditor/modules/InputModulesTab';
import InputModulesDetail from  './promptEditor/modules/InputModulesDetail';
import PromptManager from  './promptEditor/prompt/PromptTab';
import PromptDetail from  './promptEditor/prompt/PromptDetail';
import PromptGroupsManager from  './promptEditor/groups/PromptGroupsTab';
import PromptGroupsDetail from  './promptEditor/groups/PromptGroupsDetail';
import VoiceManager from  './voiceEditor/VoicesTab';
import VoiceDetail from  './voiceEditor/VoiceDetail';
import ModelManager from  './modelEditor/ModelTab';
import ModelDetail from  './modelEditor/ModelDetail';
import PromoCodesManager from  './promoCodes/PromoCodeTab';
import PromoCodesDetail from  './promoCodes/PromoCodeDetail';
import UserManagerMain from  './userManager/UserManagerMain';
import AddUser from  './userManager/AddUser';
import AddUserStoryline from  './userManager/storyLines/AddUserStoryline';
import InspectStoryline from  './userManager/storyLines/InspectStoryline';
import InspectStoryElement from  './userManager/storyLines/InspectStoryElement';
import UserDetails from  './userManager/userDetails/UserDetails';
import UserDetailsTab from  './userManager/userDetails/UserDetailsTab';
import FeedbackTab from  './feedback/FeedbackTab';
import FeedbackDetail from  './feedback/FeedbackDetail';
import AdminMainPage from  './AdminMainPage';

import LanguageTab from  './languages/LanguageTab';
import DeletionQueueTab from  './deletionQueue/deletionQueueTab';
import ContentsTab from  './contents/ContentsTab';
import CitiesTab from  './cities/CitiesTab';
import CategoriesTab from  './categories/CategoriesTab';

import StoriesTab from  './stories/StoriesTab';
import StoryMetasTab from  './stories/StoryMetasTab';
import StoryContentsTab from  './stories/StoryContentsTab';
import StoryDetails from  './stories/StoryDetails';

import GroupsMetasTab from  './groups/GroupsMetasTab';

import TrackMetasTab from  './tracks/TrackMetasTab';
import TrackContentsTab from  './tracks/TrackContentsTab';
import TrackDetails from  './tracks/TrackDetails';

import BackupDb from  './backup/BackupDb';







import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

// Weitere Komponenten-Imports

const AdminPanel = () => {
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            <Navbar bg="light" expand="lg" expanded={expanded}>
                <Navbar.Brand as={Link} to="/admin">AdminPanel</Navbar.Brand>
                <Navbar.Toggle aria-controls="admin-navbar-nav" onClick={() => setExpanded(expanded => !expanded)} />
                <Navbar.Collapse id="admin-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown title="new Stuff" id="admin-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/admin/languages" onClick={() => setExpanded(false)}>Sprachen</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/deletionQueue" onClick={() => setExpanded(false)}>Dateien zum löschen</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/contents" onClick={() => setExpanded(false)}>Contents</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/cities" onClick={() => setExpanded(false)}>Städte</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/categories" onClick={() => setExpanded(false)}>Kategorien</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/story-metas" onClick={() => setExpanded(false)}>Story Metas</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/group-metas" onClick={() => setExpanded(false)}>Gruppen Metas</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/stories" onClick={() => setExpanded(false)}>Stories</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/story-contents" onClick={() => setExpanded(false)}>Story Contents DEV</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/track-metas/" onClick={() => setExpanded(false)}>Track Metas</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/track-contents" onClick={() => setExpanded(false)}>Track Contents DEV</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/backup/db" onClick={() => setExpanded(false)}>Backup DB</NavDropdown.Item>

                        </NavDropdown>
                        <NavDropdown title="Prompt Manager" id="admin-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/admin/prompts" onClick={() => setExpanded(false)}>Prompts</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/prompts/groups" onClick={() => setExpanded(false)}>Prompt Gruppen</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/inputModule" onClick={() => setExpanded(false)}>Input Modul</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/inputType" onClick={() => setExpanded(false)}>Input Typen</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/prompts/functions" onClick={() => setExpanded(false)}>Funktionen</NavDropdown.Item>
                            {/* Weitere Dropdown-Items */}
                        </NavDropdown>
                        <NavDropdown title="Externe" id="admin-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/admin/models" onClick={() => setExpanded(false)}>Modelle</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/voices" onClick={() => setExpanded(false)}>Stimmen</NavDropdown.Item>
                            {/* Weitere Dropdown-Items */}
                        </NavDropdown>
                        <NavDropdown title="Promo Codes" id="admin-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/admin/promocodes" onClick={() => setExpanded(false)}>Promo Codes</NavDropdown.Item>
                            {/* Weitere Dropdown-Items */}
                        </NavDropdown>
                        <NavDropdown title="User Manager" id="admin-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/admin/userManager" onClick={() => setExpanded(false)}>User Manager</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/userManager/addUser" onClick={() => setExpanded(false)}>Nutzer hinzufügen</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/userManager/addUserStoryline" onClick={() => setExpanded(false)}>Nutzer Storylinne freischalten</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/userManager/userDetails/" onClick={() => setExpanded(false)}>Nutzer Liste</NavDropdown.Item>
                            {/* Weitere Dropdown-Items */}
                        </NavDropdown>
                        <NavDropdown title="Feedback" id="admin-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/admin/feedback/" onClick={() => setExpanded(false)}>Feedback</NavDropdown.Item>
                            
                            {/* Weitere Dropdown-Items */}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Routes>

                <Route path="/" element={<AdminMainPage />} />
                <Route path="/prompts" element={<PromptManager />} />
                <Route path="/prompts/:id" element={<PromptDetail />} />
                <Route path="/prompts/groups" element={<PromptGroupsManager />} />
                <Route path="/prompts/groups/:id" element={<PromptGroupsDetail />} />
                <Route path="/prompts/functions" element={<PromptFunctions />} />
                <Route path="/prompts/functions/:id" element={<FunctionDetail />} />
                <Route path="/inputType" element={<InputTypesManager />} />
                <Route path="/inputType/:id" element={<InputTypesDetail />} />
                <Route path="/inputModule" element={<InputModulesManager />} />
                <Route path="/inputModule/:id" element={<InputModulesDetail />} />
                <Route path="/voices/" element={<VoiceManager />} />
                <Route path="/voices/:id" element={<VoiceDetail />} />
                <Route path="/models/" element={<ModelManager />} />
                <Route path="/models/:id" element={<ModelDetail />} />
                <Route path="/promocodes/" element={<PromoCodesManager />} />
                <Route path="/promocodes/:id" element={<PromoCodesDetail />} />
                <Route path="/userManager/" element={<UserManagerMain />} />
                <Route path="/userManager/addUser" element={<AddUser />} />
                <Route path="/userManager/userDetails/:id" element={<UserDetails />} />
                <Route path="/userManager/userDetails/" element={<UserDetailsTab />} />
                <Route path="/userManager/addUserStoryline" element={<AddUserStoryline />} />
                <Route path="/userManager/inspectStoryline/:storylineid" element={<InspectStoryline />} />
                <Route path="/feedback/:id" element={<FeedbackDetail />} />
                <Route path="/feedback/" element={<FeedbackTab />} />
                <Route path="/userManager/inspectStoryelement/:id" element={<InspectStoryElement />} />

                <Route path="/languages/" element={<LanguageTab />} />
                <Route path="/deletionQueue/" element={<DeletionQueueTab />} />
                <Route path="/contents/" element={<ContentsTab />} />
                <Route path="/cities/" element={<CitiesTab />} />
                <Route path="/categories/" element={<CategoriesTab />} />
                <Route path="/story-metas/" element={<StoryMetasTab />} />
                <Route path="/stories/" element={<StoriesTab />} />
                <Route path="/story-contents/" element={<StoryContentsTab/>} />
                <Route path="/stories/:storyId" element={<StoryDetails />} />

                <Route path="/group-metas/" element={<GroupsMetasTab />} />
                <Route path="/track-metas/" element={<TrackMetasTab />} />
                <Route path="/track-contents/" element={<TrackContentsTab/>} />
                <Route path="/tracks/:trackId" element={<TrackDetails />} />

                <Route path="/backup/db/" element={<BackupDb />} />






                {/* Weitere Routen */}
            </Routes>

        </>
    );
};

export default AdminPanel;
