import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Badge, Card } from 'react-bootstrap';
import { fetchData } from '../../utility/ApiRequests';
import { handleSaveChanges as handleSaveChangesUtil, handleDeleteEntry, handleNewEntry, updateTrackPositionsInDB, deleteEntry } from '../../utility/dataHandling';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import EditableField from '../../utility/EditableField';
import DraggableCard from '../../utility/DraggableCard';
import SortIndicator from '../../utility/SortIndicator';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { v4 as uuidv4 } from 'uuid';
import 'leaflet/dist/leaflet.css';  // Import Leaflet CSS
import { DndProvider} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { createUniqueSlug } from '../../utility/Slug';



const StoryDetails = () => {
    const { storyId } = useParams();
    const route = '/api/stories/' + storyId;
    const contextPlural = 'Story Details';

    const [contextData, setContextData] = useState([]);
    const [metaCategories, setMetaCategories] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [storiesData, setStoriesData] = useState([]);
    const [storyContentData, setStoryContentData] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('de_DE');
    const [cities, setCities] = useState([]);
    const [storyCities, setStoryCities] = useState([]);
    const [categories, setCategories] = useState([]);
    const [storyCategories, setStoryCategories] = useState([]);
    const [tracks, setTracks] = useState([]);
    const [trackContents, setTrackContents] = useState([]);

    const [trackOrder, setTrackOrder] = useState(tracks.map((track, index) => ({ ...track, order: index + 1 })));

    const [isAddingTrack, setIsAddingTrack] = useState(false);
    const [newTrackName, setNewTrackName] = useState('');

    const [formattedContents, setFormattedContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(formattedContents);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    const handleSaveChanges = handleSaveChangesUtil(setFormattedContents, showNotification);


    

    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data);
                setMetaCategories(data.metaCategories);
                setStoriesData(data.stories);
                setLanguages(data.languages);
                setStoryContentData(data.storyContents);
                setCategoryNames(data.metaNames);
                setCities(data.cities);
                setStoryCities(data.storyCities);
                setCategories(data.categories);
                setStoryCategories(data.storyCategories);
                setTracks(data.tracks);
                setTrackContents(data.trackContents);
                setIsLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der ' + contextPlural + ':', error);
                showNotification('Fehler beim Laden der ' + contextPlural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, []);

    function transformData() {
        const languageCodeMap = languages.reduce((acc, lang) => {
            acc[lang.id] = lang.language_code;
            return acc;
        }, {});

        const metaNameMap = categoryNames.reduce((acc, name) => {
            if (!acc[name.content_key]) {
                acc[name.content_key] = {};
            }
            acc[name.content_key][languageCodeMap[name.language_id]] = name.content;
            return acc;
        }, {});

        const categoryTypeMap = metaCategories.reduce((acc, category) => {
            acc[category.id] = { type: category.type, has_translations: category.has_translations };
            return acc;
        }, {});

        const result = [];
        let newId = 1;

        storiesData.forEach(story => {
            const storyContentMap = storyContentData.filter(sc => sc.story_id === story.id).reduce((acc, sc) => {
                if (!acc[sc.meta_category_id]) {
                    acc[sc.meta_category_id] = {};
                }
                acc[sc.meta_category_id][languageCodeMap[sc.language_id] || 'null'] = { content: sc.content, id: sc.content_id, content_id: sc.id, key:sc.key};
                return acc;
            }, {});

            metaCategories.forEach(metaCategory => {
                const newEntry = {
                    id: newId++,
                    storyId: story.id,
                    meta_category_id: metaCategory.id,
                    name: metaNameMap[metaCategory.title_key]?.['de_DE'] || null,
                    type: categoryTypeMap[metaCategory.id].type,
                    has_translations: categoryTypeMap[metaCategory.id].has_translations,
                };

                languages.forEach(lang => {
                    const langCode = lang.language_code;
                    if (langCode !== 'null') {
                        newEntry[`${langCode}_name`] = storyContentMap[metaCategory.id]?.[langCode]?.content;
                        newEntry[`${langCode}_id`] = storyContentMap[metaCategory.id]?.[langCode]?.id || null;
                        newEntry[`${langCode}_content_id`] = storyContentMap[metaCategory.id]?.[langCode]?.content_id || null;
                        newEntry[`${langCode}_key`] = storyContentMap[metaCategory.id]?.[langCode]?.key || null;
                    }
                });

                newEntry['null_name'] = storyContentMap[metaCategory.id]?.['null']?.content || null;
                newEntry['null_id'] = storyContentMap[metaCategory.id]?.['null']?.id || null;
                newEntry['null_content_id'] = storyContentMap[metaCategory.id]?.['null']?.content_id || null;
                newEntry['null_content_key'] = storyContentMap[metaCategory.id]?.['null']?.key || null;

                result.push(newEntry);
            });
        });

        return result;
    }

    useEffect(() => {
        if (storyContentData) {
            setFormattedContents(transformData());
        }
    }, [storyContentData, metaCategories, languages, categoryNames]);

    const getLanguageIdByCode = (languageCode) => {
        const language = languages.find(lang => lang.language_code === languageCode);
        return language ? language.id : null;
    };

    const renderData = (myData, type) => {
        if (myData && typeof myData === 'object') {
            if (Array.isArray(myData) && myData.length === 2) {
                return `[${myData[0]}, ${myData[1]}]`;
            } else if (!Array.isArray(myData) && myData.x !== undefined && myData.y !== undefined) {
                return `[${myData.x}, ${myData.y}]`;
            }
        }
        if (type === 'image') {
            return <img src={myData} style={{ width: "1.5rem", height: "1rem" }} />;
        }
        if (type === 'audio') {
            const uniqueKey = uuidv4();
            return (
                <audio controls key={uniqueKey}>
                    <source src={myData} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            );
        }
        return myData;
    };

    const setValueData = (myData) => {
        if (myData && typeof myData === 'object') {
            if (Array.isArray(myData) && myData.length === 2) {
                return [myData[0], myData[1]];
            } else if (!Array.isArray(myData) && myData.x !== undefined && myData.y !== undefined) {
                return [myData.x, myData.y];
            }
        }
        return myData;
    };

    const getFieldType = (type) => {
        switch (type) {
            case 'geo':
                return 'point';
            case 'numeric':
                return 'float';
            case 'longtext':
                return 'textarea';
            case 'image':
                return 'image';
            case 'audio':
                return 'audio';
            case 'date':
                return 'date';
            default:
                return 'text';
        }
    };

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const getEntityNames = (storyEntities, entities, entityIdKey) => {
        const entityIds = storyEntities.map(se => se[entityIdKey]);
        return entities.filter(entity => entityIds.includes(entity.id)).map(entity => ({
            id: entity.id,
            name: entity.name
        }));
    };

    const getUnassignedEntities = (storyEntities, entities, entityIdKey) => {
        const assignedEntityIds = storyEntities.map(se => se[entityIdKey]);
        return entities.filter(entity => !assignedEntityIds.includes(entity.id));
    };

    const handleAddEntity = async (event, entityName, storyEntities, setStoryEntities, notificationMessage, entityIdKey) => {
        const entityId = event.target.value;
        if (!entityId) return;

        try {
            const newEntry = await handleNewEntry(`story_${entityName}`, dummy, dummy, {
                'story_id': storyId,
                [entityIdKey]: entityId
            });

            if (newEntry?.id) {
                setStoryEntities([...storyEntities, { id: newEntry.id, story_id: storyId, [entityIdKey]: parseInt(entityId) }]);
                showNotification(`${notificationMessage} erfolgreich hinzugefügt`, 'success');
            }
        } catch (error) {
            console.error(`Fehler beim Hinzufügen der ${notificationMessage}:`, error);
            showNotification(`Fehler beim Hinzufügen der ${notificationMessage}`, 'danger');
        }
    };

    const handleRemoveEntity = async (entityId, storyEntities, setStoryEntities, entityName, notificationMessage, entityIdKey) => {
        try {
            const entry = storyEntities.find(se => se[entityIdKey] === entityId && parseInt(se.story_id) === parseInt(storyId));
            if (entry) {
                await handleDeleteEntry(`story_${entityName}`, entry.id, dummy, dummy);
                setStoryEntities(storyEntities.filter(se => se[entityIdKey] !== entityId));
                showNotification(`${notificationMessage} erfolgreich entfernt`, 'success');
            }
        } catch (error) {
            console.error(`Fehler beim Entfernen der ${notificationMessage}:`, error);
            showNotification(`Fehler beim Entfernen der ${notificationMessage}`, 'danger');
        }
    };

    // Nutzung für Städte
    const getCityNames = () => getEntityNames(storyCities, cities, 'city_id');
    const getUnassignedCities = () => getUnassignedEntities(storyCities, cities, 'city_id');
    const handleAddCity = (event) => handleAddEntity(event, 'cities', storyCities, setStoryCities, 'Stadt', 'city_id');
    const handleRemoveCity = (cityId) => handleRemoveEntity(cityId, storyCities, setStoryCities, 'cities', 'Stadt', 'city_id');

    // Nutzung für Kategorien
    const getCategoryNames = () => getEntityNames(storyCategories, categories, 'category_id');
    const getUnassignedCategories = () => getUnassignedEntities(storyCategories, categories, 'category_id');
    const handleAddCategory = (event) => handleAddEntity(event, 'categories', storyCategories, setStoryCategories, 'Kategorie', 'category_id');
    const handleRemoveCategory = (categoryId) => handleRemoveEntity(categoryId, storyCategories, setStoryCategories, 'categories', 'Kategorie', 'category_id');


    useEffect(() => {
        if (tracks && tracks.length > 0) {
            const initialOrder = tracks
                .sort((a, b) => a.position - b.position)
                .map((track, index) => {
                    const trackData = trackContents.filter(tc => tc.track_id === track.track_id);
                    const name = trackData.reduce((acc, tc) => {
                        const langCode = languages.find(lang => lang.id === tc.language_id)?.language_code;
                        if (langCode) {
                            if (tc.meta_category_id === 2) {
                                acc[`${langCode}_audioUrl`] = tc.content;
                                acc[`${langCode}_audioUrl_id`] = tc.content_id;
                            }
                            if (tc.meta_category_id === 1) {
                                acc[`${langCode}_name`] = tc.content;
                                acc[`${langCode}_name_id`] = tc.content_id;
                            }
                        }
                        return acc;
                    }, {});
                    const geoLocation = trackData.find(tc => tc.meta_category_id === 6)?.content;
                    const geoLocation_id = trackData.find(tc => tc.meta_category_id === 6)?.content_id;
                    return {
                        ...track,
                        ...name,
                        geoLocation,
                        geoLocation_id,
                        position: index + 1 // Reassign positions here
                    };
                });
            setTrackOrder(initialOrder);
            setTracks(tracks.map((track, index) => ({
                ...track,
                position: index + 1
            })));
        } else {
            setTrackOrder([]); // Ensures that the track order is reset if there are no tracks
        }
    }, [trackContents, languages]);
    
    const moveTrack = useCallback((dragIndex, hoverIndex, finalize = false) => {
        const draggedTrack = trackOrder[dragIndex];
        const newTrackOrder = update(trackOrder, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, draggedTrack],
            ],
        });
        const updatedTrackOrder = newTrackOrder.map((track, index) => ({
            ...track,
            position: index + 1,
        }));
    
        setTrackOrder(updatedTrackOrder);
        setTracks(updatedTrackOrder.map(track => ({
            id: track.id,
            story_id: track.story_id,
            track_id: track.track_id,
            position: track.position
        })));
    
        if (finalize) {
            handleSavePositionsToDb(updatedTrackOrder);
        }
    }, [trackOrder]);
    
    const handleSavePositionsToDb = async (tracks) => {
        await updateTrackPositionsInDB(tracks);
    };
    
    const handleAddNewEntry = () => {
        setIsAddingTrack(true);
    };
    
    const handleCancelAdd = () => {
        setIsAddingTrack(false);
        setNewTrackName('');
    };
    
    const handleSaveNewTrack = async () => {

        const existingSlugs = tracks.map(data => data.slug);
        const slug = createUniqueSlug(newTrackName, existingSlugs);

        // WICHTIG: die Sprache de_DE muss existieren
        const languageId = getLanguageIdByCode('de_DE');
        if (languageId) {
            const newTrack = await handleNewEntry('tracks', dummy, showNotification, {
                'state': 'published',
            });
            const newTrackInStory = await handleNewEntry('story_tracks', dummy, showNotification, {
                'story_id': storyId,
                'track_id': newTrack.id,
                'position': tracks.length + 1,
                'slug' : slug
            });
            const newTrackTitleContent = await handleNewEntry('text_contents', dummy, showNotification, {
                'content': newTrackName,
            });
            const newTrackMetaContent = await handleNewEntry('track_contents', dummy, showNotification, {
                'track_id': newTrack.id,
                'meta_category_id': 1,
                'content_id': newTrackTitleContent.id,
                'language_id': 2
            });
    
            // füge das zu tracks hinzu
            setTracks(prevTracks => {
                const updatedTracks = [
                    ...prevTracks,
                    { id: newTrackInStory.id, story_id: parseInt(storyId), track_id: newTrack.id, position: prevTracks.length + 1 }
                ];
                return updatedTracks.map((track, index) => ({
                    ...track,
                    position: index + 1
                }));
            });
    
            // füge das zu setTrackContents hinzu
            setTrackContents(prevTrackContents => [
                ...prevTrackContents,
                { id: newTrackMetaContent.id, track_id: newTrack.id, meta_category_id: 1, content_id: newTrackTitleContent.id, language_id: languageId, content: newTrackName }
            ]);
    
        } else {
            showNotification('Sprache de_DE existiert nicht.', 'danger');
        }
    
        setIsAddingTrack(false);
        setNewTrackName('');
    };
    
    const handleDeleteTrack = async (storyTrackId, trackId) => {
        const deleteTrack = await handleDeleteEntry('story_tracks', storyTrackId, dummy, showNotification);
        if (deleteTrack) {
            setTracks(prevTracks => {
                const updatedTracks = prevTracks.filter(track => parseInt(track.id) !== parseInt(storyTrackId));
                // Reassign positions here
                return updatedTracks.map((track, index) => ({
                    ...track,
                    position: index + 1
                }));
            });
            setTrackContents(prevTrackContents => prevTrackContents.filter(track => parseInt(track.track_id) !== parseInt(trackId)));
        }
    };


    const dummy =() =>{
        return;
    }


    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }


    if (contextData.length===0){
        return <div>keine Story gefunden</div>
    }

    return (
        <div>
            <h2>{contextPlural}</h2>
            Hauptsächlich für Debug Zwecke und zum schnellen Erstellen von Übersetzungen
            <div className="content-right">
                <Button onClick={() => console.log(contextData)}>
                    DEBUG1
                </Button>
                <Button onClick={() => console.log(trackContents)}>
                    DEBUG2
                </Button>
                <Button onClick={() => console.log(trackOrder)}>
                    DEBUG3
                </Button>
                <Button onClick={() => console.log(tracks)}>
                    Track
                </Button>
            </div>
            <div className="content-right">
                <Button onClick={() => setEditActive(!editActive)}>
                    Bearbeitung {editActive ? 'deaktivieren' : 'aktivieren'}
                </Button>
            </div>
            <Form.Group controlId="languageSelect">
                <Form.Label>Sprache auswählen</Form.Label>
                <Form.Control  className="w-8" as="select" value={selectedLanguage} onChange={handleLanguageChange}>
                    {languages.filter(lang => lang.language_code !== 'null').map(lang => (
                        <option key={lang.id} value={lang.language_code}>
                            {lang.language_name}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('name')}>
                            Name
                            <SortIndicator isSorted={sortConfig.key === 'name'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort(`${selectedLanguage}_name`)}>
                            Wert {selectedLanguage}
                            <SortIndicator isSorted={sortConfig.key === `${selectedLanguage}_name`} direction={sortConfig.direction} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                {sortedContextData.map(lan => {
                    const metaCategory = metaCategories.find(category => category.id === lan.meta_category_id);
                    const languageId = metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage);
                    const languageField = metaCategory && metaCategory.has_translations === 0 ? 'null_name' : `${selectedLanguage}_name`;
                    const languageIdField = metaCategory && metaCategory.has_translations === 0 ? 'null_id' : `${selectedLanguage}_id`;

                    return (
                        <tr key={lan.id}>
                            <td style={metaCategories.some(category => category.id === lan.meta_category_id && category.has_translations === 0) ? { backgroundColor: 'lightgreen' } : {}}>{lan.name}</td>
                            <td style={lan['null_name'] ? { backgroundColor: 'lightgreen' } : {}}>
                                {editActive ? (
                                    <EditableField
                                        value={setValueData(lan['null_name']) || setValueData(lan[`${selectedLanguage}_name`]) || ''}
                                        //geoObjects={geoObjects}
                                        {...(getFieldType(lan.type) === 'image' && { 
                                            fileId: lan['null_id'] || lan[`${selectedLanguage}_id`],
                                            height: "8rem",
                                            width: "8rem",
                                            fileKey: lan['null_name_key'] || lan[`${selectedLanguage}_key`],
                                            fileDestination: "image_contents",
                                            fileUsageName: "image",
                                            languageId: metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage),
                                            
                                        })}
                                        {...(getFieldType(lan.type) === 'audio' && { 
                                            fileId: lan['null_id'] || lan[`${selectedLanguage}_id`],
                                            height: "8rem",
                                            width: "8rem",
                                            fileKey: lan['null_name_key'] || lan[`${selectedLanguage}_key`],
                                            fileDestination: "audio_contents",
                                            fileUsageName: "audio",
                                            languageId: metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage),
                                            
                                        })}
                                        placeholder={lan.name}
                                        ifEmpty="---"
                                        onSave={async (newValue) => {
                                            const metaCategory = metaCategories.find(category => category.id === lan.meta_category_id);
                                            const languageField = metaCategory && metaCategory.has_translations === 0 ? 'null_name' : `${selectedLanguage}_name`;
                                            const languageIdField = metaCategory && metaCategory.has_translations === 0 ? 'null_id' : `${selectedLanguage}_id`;

                                            if (lan[languageField]) {
                                                    if (getFieldType(lan.type) === 'image' || getFieldType(lan.type) === 'audio') {
                                                        const { file_url, filename } = newValue;
                                                        setStoryContentData(prevContextData =>
                                                            prevContextData.map(item =>
                                                                item.id === lan[`${selectedLanguage}_content_id`] || item.id === lan['null_content_id']
                                                                    ? { ...item, content: file_url, key: filename }
                                                                    : item
                                                            )
                                                        );
                                                } else {
                                                    handleSaveChanges(newValue, languageField, lan.id, lan.type + '_contents', 'content', lan[languageIdField]);
                                                }
                                            } else {
                                                let newContentEntry ={}
                                                if (getFieldType(lan.type) === 'image' || getFieldType(lan.type) === 'audio') {
                                                    newContentEntry.id = newValue.file_id;

                                                } else {
                                                newContentEntry = await handleNewEntry(lan.type + '_contents', dummy, dummy, {
                                                    'content': newValue,
                                                });
                                            }

                                                if (newContentEntry?.id) {
                                                    const languageId = metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage);
                                                    const newStoryContentEntry = await handleNewEntry('story_contents', dummy, showNotification, {
                                                        'story_id': lan.storyId,
                                                        'meta_category_id': lan.meta_category_id,
                                                        'content_id': newContentEntry.id,
                                                        'language_id': languageId
                                                    });
                                                    if (getFieldType(lan.type) === 'image' || getFieldType(lan.type) === 'audio') {
                                                        newStoryContentEntry['content'] = newValue.file_url;
                                                        newStoryContentEntry['key'] = newValue.file_name;
                                                    } else{
                                                        newStoryContentEntry['content'] = newValue;
                                                    }
                                                    
                                                    setStoryContentData(prevContextData => [...prevContextData, newStoryContentEntry]);
                                                }
                                            }
                                        }}
                                        fieldType={getFieldType(lan.type)}
                                    />
                                ) : (
                                    renderData(lan['null_name'] || lan[`${selectedLanguage}_name`], lan.type) || <span className='text-red'>noch kein Wert</span>
                                )}
                                {editActive && (
                                    <Trash onClick={() => {
                                        handleDeleteEntry('story_contents', lan[`${selectedLanguage}_content_id`] || lan['null_content_id'], dummy, showNotification);
                                        setStoryContentData(prevContextData => prevContextData.filter(item => item.id !== lan[`${selectedLanguage}_content_id`] && item.id !== lan['null_content_id']));
                                    }} />
                                )}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div>
                <Form.Group controlId="unassignedCities">
                    <Form.Label><h3>Stadt hinzufügen</h3></Form.Label>
                    <Form.Control className="w-12" as="select" onChange={handleAddCity}>
                        <option value="">Stadt auswählen...</option>
                        {getUnassignedCities().map(city => (
                            <option key={city.id} value={city.id}>
                                {city.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>

                {storyCities.length > 0 ? (
                    <div>
                        <span>zugeordnete {storyCities.length === 1 ? 'Stadt' : 'Städte'}: </span>
                        {getCityNames().map(city => (
                            <Badge key={city.id} pill variant="primary" className="m-1 pa-05">
                                {city.name}
                                <span
                                    style={{ cursor: 'pointer', marginLeft: '0.5rem', color: 'red' }}
                                    onClick={() => handleRemoveCity(city.id)}
                                >
                                    &times;
                                </span>
                            </Badge>
                        ))}
                    </div>
                ) : (
                    <p>Keine Stadt zugeordnet</p>
                )}
            </div>

            <div>
                <Form.Group controlId="unassignedCategories">
                    <Form.Label><h3>Kategorie hinzufügen</h3></Form.Label>
                    <Form.Control className="w-12" as="select" onChange={handleAddCategory}>
                        <option value="">Kategorie auswählen...</option>
                        {getUnassignedCategories().map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>

                {storyCategories.length > 0 ? (
                    <div>
                        <span>Zugeordnete {storyCategories.length === 1 ? 'Kategorie' : 'Kategorien'}:</span>
                        {getCategoryNames().map(category => (
                            <Badge key={category.id} pill variant="primary" className="m-1 pa-05">
                                {category.name}
                                <span
                                    style={{ cursor: 'pointer', marginLeft: '0.5rem', color: 'red' }}
                                    onClick={() => handleRemoveCategory(category.id)}
                                >
                                    &times;
                                </span>
                            </Badge>
                        ))}
                    </div>
                ) : (
                    <p>Keine Kategorie zugeordnet</p>
                )}
            </div>


            <div>
                <h3>Verfügbare Tracks</h3>
                {trackOrder.length > 0 ? (
                    <DndProvider backend={HTML5Backend}>
                        {trackOrder.map((track, index) => (
                            <DraggableCard
                                key={track.id}
                                index={index}
                                track={track}
                                trackOrder={trackOrder}
                                setTrackOrder={setTrackOrder}
                                moveTrack={moveTrack}
                                selectedLanguage={selectedLanguage}
                                deleteTrack={handleDeleteTrack}
                            />
                        ))}
                    </DndProvider>
                ) : (
                    <p>Keine Tracks verfügbar</p>
                )}
            </div>
            {!isAddingTrack && (
                <Button onClick={handleAddNewEntry}>Neuen Track hinzufügen</Button>
            )}
            {isAddingTrack && (
                <div><p><strong>WICHTIG</strong>: der Datentyp kann später nicht editiert werden</p>
                <p>Name
                    <input className='ml-1'
                        type="text"
                        value={newTrackName}
                        onChange={(e) => setNewTrackName(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSaveNewTrack()}
                        placeholder={`Trackname auf Deutsch`}
                    />
                    </p>
                    <p>
                    <Button onClick={handleSaveNewTrack}>Speichern</Button>
                    <Button className='ml-1' onClick={handleCancelAdd}>Abbrechen</Button>
                    </p>
                </div>
            )}
        </div>
    );
};

export default StoryDetails;

