import React, { createContext, useState, useEffect, useCallback } from 'react';

export const WalkDataContext = createContext();

export const WalkDataProvider = ({ children }) => {
  const [storyData, setStoryData] = useState(null);
  const [language, setLanguage] = useState(2);
  const [storyMetas, setStoryMetas] = useState(null);
  const [tracks, setTracks] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [playerSize, setPlayerSize] = useState('small');
  const [activeTab, setActiveTab] = useState('info');
  const [isPlaying, setIsPlaying] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid]=useState(false);
  const [validTrackSlugs, setValidTrackSlugs] = useState([]);
  const [passwordAttempted, setPasswordAttempted] = useState(false);

  const handleTabChange = useCallback((newTab) => {
    setActiveTab(newTab);
    setPlayerSize(newTab === 'home' ? 'big' : 'small');
  }, []);

  useEffect(() => {
    handleTabChange(activeTab);
  }, [activeTab, handleTabChange]);

  const validatePassword = (inputPassword, clearPassword) => {
    setPasswordAttempted(true);
    const newValidSlugs = tracks
      .filter(track => track.metadata?.trackMeta_password?.['1'] === inputPassword)
      .map(track => track.slug);
  
    if (newValidSlugs.length > 0) {
      setIsPasswordValid(true);
      clearPassword();
      setValidTrackSlugs(prevValidSlugs => [...new Set([...prevValidSlugs, ...newValidSlugs])]);
    } else {
      setIsPasswordValid(false);
    }
  };

  return (
    <WalkDataContext.Provider value={{
      storyData, setStoryData,
      language, setLanguage,
      storyMetas, setStoryMetas,
      tracks, setTracks,
      currentTrack, setCurrentTrack,
      playerSize, setPlayerSize,
      activeTab, setActiveTab,
      isPlaying, setIsPlaying,
      password, setPassword,
      isPasswordValid, setIsPasswordValid,
      handleTabChange,
      validatePassword,
      validTrackSlugs, setValidTrackSlugs,
      passwordAttempted, setPasswordAttempted
    }}>
      {children}
    </WalkDataContext.Provider>
  );
};