import React, { useState, useEffect } from 'react';
import { ReactComponent as PencilIcon } from 'bootstrap-icons/icons/pencil.svg';
import { ReactComponent as SaveIcon } from 'bootstrap-icons/icons/save.svg';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { ReactComponent as Close } from 'bootstrap-icons/icons/x-circle.svg';
import { useNotification } from '../hooks/useNotifications';
import { Modal, Button } from 'react-bootstrap';
import { uploadFile } from '../utility/dataHandling';  // Import der neuen Upload-Funktion
import MapPicker from './MapPicker';

const EditableField = ({
    value,
    onSave,
    fieldType,
    dropdownOptions,
    minValue,
    maxValue,
    size,
    height,
    width,
    fileKey,
    fileId,
    fileDestination,
    fileUsageName,
    stepSize = 1,
    className,
    modules = [],
    colorMapping = {},
    placeholder,
    ifEmpty,
    lastEditedPoint,
    storyStartingPoint,
    cityLocation,
    geoObjects,
    mapImage
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editableValue, setEditableValue] = useState(value || ''); // Ensure initial value is not null
    const [valueValid, setValueValid] = useState(true);
    const { showNotification } = useNotification();
    const [isUploading, setIsUploading] = useState(false);
    const [showMap, setShowMap] = useState(true);

    useEffect(() => {
        setEditableValue(value || ''); // Ensure value is not null
    }, [value]);

    const handleSave = () => {
        if (editableValue.length < minValue) {
            showNotification(`Eingabe muss mindestens ${minValue} Zeichen lang sein.`, 'warning');
            return;
        }
        onSave(editableValue);
        setIsEditing(false);
    };

    const handleClose = () => {
        setEditableValue(value); // Reset to original value
        setIsEditing(false);
    };

    const determineDecimalPlaces = (stepSize) => {
        const decimalPart = stepSize.toString().split('.')[1];
        return decimalPart ? decimalPart.length : 0;
    };

    const highlightText = (text, modules, colorMapping) => {
        if (colorMapping.length === 0 || modules.length === 0) {
            return text;
        }
        const regex = new RegExp(`(${modules.map(m => m.placeholder).join('|')})`, 'g');
        return text.split(regex).map((part, index) => {
            const module = modules.find(m => m.placeholder === part);
            const style = module ? { backgroundColor: colorMapping[module.placeholder] } : {};
            return <span key={index} style={style}>{part}</span>;
        });
    };

    const getDropdownLabel = (value) => {
        let option;
        if (dropdownOptions) option = dropdownOptions.find(option => option.value.toString() === value.toString());
        return option ? option.label : 'Unbekannt';
    };

    const validateInputLength = (value, minValue, maxValue) => {
        const inputLength = value.length;
        if (minValue !== undefined && inputLength < minValue) {
            setValueValid(false);
            return false;
        }
        if (maxValue !== undefined && inputLength > maxValue) {
            setValueValid(false);
            return false;
        }
        setValueValid(true);
        return true;
    };

    //if (fieldType ==='point'){
    const handleMapSelect = (newPosition) => {
        setEditableValue(newPosition);
        setShowMap(false);
    };

    const getMapInitialPosition = () => {
        if (editableValue) return editableValue;
        if (lastEditedPoint) return lastEditedPoint;
        if (storyStartingPoint) return storyStartingPoint;
        if (cityLocation) return cityLocation;
        return [50.785, 10.349]; // Default-Wert
    };

    const getMapZoomLevel = (position) => {
        if (position === editableValue) return 15;
        if (position === lastEditedPoint) return 14;
        if (position === storyStartingPoint) return 13;
        if (position === cityLocation) return 10;
        return 5; // Default-Wert
    };

    const initialMapPosition = getMapInitialPosition();
    const zoomMapLevel = getMapZoomLevel(initialMapPosition);
//}
    const commonKeyUpHandler = (e) => {
        if (e.key === 'Enter' && fieldType !== 'textarea' && valueValid) {
            handleSave();
        }
    };

    const renderInput = () => {
        switch (fieldType) {
            case 'text':
                return (
                    <input
                        type="text"
                        minLength={minValue}
                        maxLength={maxValue}
                        size={size}
                        placeholder={placeholder}
                        value={editableValue}
                        onChange={(e) => {
                            const inputLength = e.target.value.length;
                            if (inputLength < minValue) {
                                showNotification(`Eingabe muss mindestens ${minValue} Zeichen lang sein.`, 'warning');
                            } else if (inputLength >= maxValue) {
                                showNotification(`Maximale Länge von ${maxValue} Zeichen erreicht.`, 'info');
                            }
                            validateInputLength(e.target.value, minValue, maxValue);
                            setEditableValue(e.target.value);
                        }}
                        onKeyUp={(e) => {
                            const inputLength = e.target.value.length;
                            if (inputLength >= maxValue) {
                                showNotification('Keine weiteren Zeichen erlaubt.', 'info');
                            }
                            commonKeyUpHandler(e);
                        }}
                    />
                );

                case 'textarea':
                    return (
                        <textarea
                            className={className}
                            value={editableValue}
                            onChange={(e) => setEditableValue(e.target.value)}
                        ></textarea>
                    );

            case 'dropdown':
                return (
                    <select value={editableValue} onChange={(e) => setEditableValue(e.target.value)} onKeyUp={commonKeyUpHandler}>
                        {dropdownOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                );
            case 'number':
                return (
                    <input
                        type="number"
                        min={minValue}
                        max={maxValue}
                        value={editableValue}
                        onChange={(e) => {
                            let value = parseInt(e.target.value, 10);
                            if (isNaN(value)) {
                                value = '';
                            } else if (value > maxValue) {
                                value = maxValue;
                                showNotification('Wert muss kleiner als ' + maxValue + ' sein.', 'warning');
                            } else if (value < minValue) {
                                value = minValue;
                                showNotification('Wert muss größer als ' + minValue + ' sein.', 'warning');
                            }
                            setEditableValue(value);
                        }}
                        onKeyUp={commonKeyUpHandler}
                    />
                );
            case 'float':
                    return (
                        <input
                            type="number"
                            min={minValue}
                            max={maxValue}
                            step="0.01"
                            value={editableValue}
                            onChange={(e) => {
                                let value = parseFloat(e.target.value, 10);
                                if (isNaN(value)) {
                                    value = '';
                                } else if (value > maxValue) {
                                    value = maxValue;
                                    showNotification('Wert muss kleiner als ' + maxValue + ' sein.', 'warning');
                                } else if (value < minValue) {
                                    value = minValue;
                                    showNotification('Wert muss größer als ' + minValue + ' sein.', 'warning');
                                }
                                setEditableValue(value);
                            }}
                            onKeyUp={commonKeyUpHandler}
                        />
                    );


                    case 'point':
                        return (
                            <div>
                                <input
                                    type="text"
                                    value={editableValue ? `[${editableValue[0]}, ${editableValue[1]}]` : ''}
                                    onClick={() => setShowMap(true)}
                                    readOnly
                                />
                                <Modal show={showMap} onHide={() => setShowMap(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Pick a location</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <MapPicker 
                                            initialPosition={initialMapPosition}
                                            zoomLevel={zoomMapLevel}
                                            onPositionSelect={handleMapSelect} 
                                            geoObjects={geoObjects}
                                            mapImage={mapImage}
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setShowMap(false)}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        );

            case 'range':
                return (
                    <span>
                        <span style={{ marginRight: '1rem' }}>
                            {editableValue.toFixed(determineDecimalPlaces(stepSize))}
                        </span>
                        <input
                            type="range"
                            min={minValue}
                            max={maxValue}
                            step={stepSize}
                            value={editableValue}
                            onChange={(e) => setEditableValue(parseFloat(e.target.value))}
                            onKeyUp={commonKeyUpHandler}
                        />
                    </span>
                );


            case 'date':
                return (
                    <span>
                        <input
                            type="date"
                            value={editableValue}
                            min={minValue}
                            max={maxValue}
                            onChange={(e) => setEditableValue(e.target.value)}
                            onKeyUp={commonKeyUpHandler}
                        />
                    </span>
                );
            case 'image':
            case 'audio':
                const handleFileChange = async (event) => {
                    const file = event.target.files[0];
                    if (!file) return;

                    if (file.size > 500000000) { // 5MB Limit
                        alert("Datei zu groß.");
                        return;
                    }

                    setIsUploading(true); // Beginn des Uploads

                    try {
                        const response = await uploadFile(file, fileId, fileDestination, fileUsageName, fileKey);
                        if (response.status === 200) {
                            setEditableValue(response.data.signedUrl);
                            showNotification('Änderungen gespeichert', 'success');
                            onSave({ 'file_url': response.data.signedUrl, 'file_name': response.data.filename, 'file_id': response.data.fileId});
                        } else {
                            showNotification('Fehler beim Speichern der Daten', 'danger');
                            console.error('Fehler beim Speichern der Daten');
                        }
                    } catch (error) {
                        console.error("Upload Fehler: ", error.message);
                        alert(error.message);
                    } finally {
                        setIsUploading(false); // Beende den Upload-Prozess
                        setIsEditing(false);
                    }
                };

                return (
                    <span>
                        {isUploading ? (
                            <div><div className="spinner"></div> Lädt... </div>// Zeigt den Lade-Spinner an
                        ) : (
                            <>
                                <input type="file" onChange={handleFileChange} />

                                <Close onClick={handleClose} />
                            </>
                        )}
                    </span>
                );

            default:
                return null;
        }
    };

    return (
        <span className="editable-field">
            {isEditing ? (
                <>
                    {renderInput()}
                    {!['image', 'file', 'audio'].includes(fieldType) && (
                        <>
                            <SaveIcon className='editicon' onClick={handleSave} />
                            <Close className='editicon' onClick={handleClose} />
                        </>
                    )}
                </>
            ) : (
                <>
                    {!['image', 'file', 'audio'].includes(fieldType) && (
                        fieldType === 'dropdown' ? getDropdownLabel(value) :
                            fieldType === 'point' ? `[${value[0]}, ${value[1]}]` :
                                value ? highlightText(value, modules, colorMapping) : ifEmpty
                    )}
                    {fieldType === 'image' && (
                        <img src={value} style={{ width: width, height: height }} />
                    )}
                    {fieldType === 'audio' && (
                        <audio controls key={value}>
                            <source src={value} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    )}
                    <PencilIcon className='editicon' onClick={() => setIsEditing(true)} />
                </>
            )}
        </span>
    );
};

export default EditableField;